@use 'abstracts/colors' as *;

.col-vert-center { align-self: center; }
.text--center { text-align:center; }
.text--strong { font-weight: 700; }
.text--left { text-align:left; }

.p--2 { padding: 2em;}
.py--3 { padding:3em 0; }
.pt--3 { padding-top:3em; }
.pb--3 { padding-bottom:3em; }
.py--6 { padding:6em 0; }
.pt--6 { padding-top:6em; }
.pb--6 { padding-bottom:6em; }
.mb--3 { margin-bottom:3em; }

.text--outline { -webkit-text-stroke-width: 1px; -webkit-text-stroke-color: rgb(255, 255, 255); color:$darkgrey; }
.list-horizontal { list-style:none; padding:0; margin:0; } 
.list-horizontal li { display:inline-block; margin-left:.2em; }
.list-horizontal li:last-child { margin-left:0; }
.lock--scroll { overflow-y:hidden; }
