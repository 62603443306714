@use 'abstracts/colors' as *;
@use 'utility/breakpoints' as *;

footer { 
    padding:0 2.5vw; 
    min-height:35rem; 
    background-color:$orange;
    position:relative;
    display:flex;
    justify-content:center;
    }

.footer--inner {
    display:flex;
    flex-direction:column; 
    justify-content:center; 
    align-items:center;
    z-index:4;  
}

.footer--img {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-image: url(../img/hero-background-2.jpg); 
    background-size:cover; 
    background-repeat:no-repeat; 
    background-position:bottom center; 
    mix-blend-mode: multiply;
}

.logo--footer { 
    width:100%; 
    max-width:10rem; 
}
.logo--footer--sub { 
    font-weight:400; 
    margin-bottom:3em;
}
.fixed--footer { 
    height:35rem; 
    position:fixed; 
    bottom:0; 
    z-index:-1; 
}
.footer--menu li { 
    margin-right:.8em; 
}
.footer--menu li:last-child { 
    margin-right:0; 
}
.footer--menu li a {  
    color:white; 
    text-decoration:none; 
    font-size:var(--fs-xs); 
    transition:.5s ease;
}
.footer--menu li a:hover { color:rgb(255, 255, 255); text-decoration:underline; }
.social--menu { margin: 0; }
.social--menu li:first-child svg { transform: scale(.55);}
.social--menu li svg { transform: scale(.8);}
#scroll--top { display:none; position: absolute; bottom: 0; width:0; height:0; border-top:25px solid transparent; border-bottom:25px solid $orange; border-left:25px solid transparent; border-right: 25px solid transparent; transition: .5s ease;}
#scroll--top:hover { cursor:pointer; border-bottom:25px solid white; }

@include mq(medium) { 
    .logo--footer { max-width:20rem; }  
}