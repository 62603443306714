@use 'abstracts/colors' as *;
@use 'abstracts/fonts' as *;
@use 'utility/breakpoints' as *;

#meter { display:none; }
#meter--index { position:absolute; top:0; left:0; z-index:1; }
.meter--pointer { display:block; width:10px; height:100%; position:absolute; z-index:2; }
.pointer--arrow { width:0; height:0; position:absolute; top:5rem; left:0; border-top:10px solid transparent; border-bottom:10px solid transparent; border-left:20px solid $orange; }
.line { width:10px; height:1px; background-color:$lightgrey; margin-bottom:25px; }
.line:nth-child(6n+0) { width:20px;}

@include mq(large) {
    #meter { display:block; position:absolute; background: transparent url(../img/meter-bg.svg) repeat-y -12px 25px; left:0; top:0; width:45px; height:100%; }
}