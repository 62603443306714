@use 'abstracts/fonts' as *;
@use 'abstracts/colors' as *;
@use 'utility/breakpoints' as *;

section { 
    padding: 3em 0;  
    display:flex; 
    flex-direction:row; 
    align-items:center;

    @media(min-width:40em) {
        min-height:100vh;
        padding: 5em 0;
    }
}

.container { 
    margin-inline:auto; 
    width: min(90%, 70.5rem); 
    position:relative; 
    display: flex; 
    flex-direction:column; 
    justify-content:center;
 }

.divider-container { 
    margin-inline:auto; 
    width: min(90%, 70.5rem); 
    padding:1em 0; 
    position:relative;

    .divider { 
        display: flex; 
        justify-content: center; 

        .divider--icon { 
            width:2px; 
            height:80px; 
            background-color:$orange; 
        }

        .divider--icon:nth-child(2) { 
            width: 2px;
            height: 30px;
            background-color:$orange;
            transform-origin: bottom;
            position: absolute;
            z-index: 20;
            bottom: 17px;
            transform: rotate(-45deg);
        }

        .divider--icon:nth-child(3) { 
            width: 2px;
            height: 30px;
            background-color:$orange;
            transform-origin: bottom;
            position: absolute;
            z-index: 20;
            bottom: 17px;
            transform: rotate(45deg);
        }

    }
}

.section--img { 
    height:20rem; 

    @media(min-width:40em) {
        height:40rem;
    }
}

.section--index { 
    position:relative; 
    width:100%; min-height:7rem; 
    display:flex; 
    justify-content: 
    flex-start; 
    align-items:center; 

    @media(min-width:40em) {
        position:absolute; 
        top: 15%;
        right: 10%; 
        flex-direction: column;
        z-index:1; 
        width:auto; 
    }
}

.text-wrapper { 
    max-width:55rem; 
}

.wordsParent, .titleParent { 
    overflow:hidden; 
}

.linesParent, .textParent { 
    overflow:hidden;
 }

.linesChild, .wordsParent, .titleParent { 
    will-change:transform; 
} 

.index--nr { 
    color:$darkgrey; 
    font-weight: 600; 
    font-size:4.209rem;  
    font-family:$font-secondary; 
    line-height:.5; 
    opacity: .5; 
    perspective:500px; 
    min-height:0; 

    @media(min-width:40em) {
        color:$darkgrey; 
        font-weight: 600; 
        font-size:7.478rem;   
        font-family:$font-secondary; 
        line-height:.5; 
        opacity: .5; 
        perspective:500px; 
        min-height:0;
    }
}

.index--line { 
    position: absolute; 
    top: 7%; 
    z-index: 9; 
    right: 0; 
    width:80px;
    display:none;

    hr { 
        width:75%; 
        height: 2px; 
        background-color: $orange; 
        border: none;
    }

    @media(min-width:40em) {
        display:block; 
        position: absolute; 
        top:92%; 
        z-index:9; 
        right:2%; 
        width:80px;
    }
}

.parallax--wrapper { 
    margin-bottom:1.5em 
}

.circle--container { 
    display:none; 
    

    .circle--text {
        width:40rem; 
        height:40rem; 
        position:relative;

        svg {
            max-width:40rem;
         }
    }

    @media(min-width:40em) {
        display:flex; 
        align-items: center; 
        justify-content: center;
        width:55rem; 
        height:55rem; 
        position:absolute; 
        top:-18rem; 
        right:-20%; 
    }
}

#artist--management {
    .section--img { 
        background-image:url(../img/artist-management.jpg); 
    }
}

#production--management { 
    .section--img { 
        background-image:url(../img/production-management.jpg); 
    } 
}

#licensing--management {
    .section--img { 
        background-image:url(../img/licencing-management.jpg); 
    }
}

#about {
    .container { 
        display:flex;  
        flex-direction:column; 
        align-items:center; 
        justify-content:center;
    }

    img {
        max-width:100%;
    }    

    @media(min-width:40em) {
        .container { 
            flex-direction:column; 
            align-items:center; 
            justify-content:center; 
        }

        img {
            max-width:28rem;
        }
    }

    @media(min-width:65em) {
        .container { 
            flex-direction:row; 
            align-items:center; 
            justify-content:center; 
        }
    }
}

.quote { 
    text-transform:uppercase; 
    font-family:$font-secondary; 
    font-weight:600; 
    font-size:var(--fs-500); 
    line-height:1; 
    position:relative; 
    width:100%;
    padding:1em 0; 

    .quota { 
        display:block; 
        color:$orange; 
        font-size:var(--fs-400); 
        font-family:$font-primary; 
        padding:.8em 0; 
        z-index:1; 
    }

    @media(min-width:40em) {
        padding:1.5em;
    }

}

.client--list {
    text-transform:uppercase; 
    font-weight:600; 
    margin:0 auto; 
    list-style:none; 
    padding:0; 
    display:flex;
    flex-wrap: wrap;
    justify-content: center;

    li:after {
        content: "-";
        color:$orange;
        padding:0 .5em;
    }

    li:last-child:after {
        content: "";
        padding:0;
    }

}

.client--brands { 
    margin:2em auto 0 auto; 
    display: grid; 
    grid-template-columns: repeat(3, 1fr); 
    grid-gap: 1.5em;

    .grid--item {
        img {
            max-width:6rem;
        }
    }

    @media(min-width:40em) {
        grid-template-columns: repeat(3, 1fr); 

        .grid--item {
            img {
                max-width:13rem;
            }
        }

    }

    @media(min-width:65em) {
        grid-template-columns: repeat(4, 1fr); 
    }
    
}


.contact-btn { 
    font-size: var(--fs-500);
    font-family: acumin-pro-extra-condensed, Verdana, sans-serif;
    color: #ffffff;
    text-transform: uppercase;
    line-height: 1;
    transition: 0.5s ease;
    text-decoration: none;
    display: block;
    background: #d73c2e;
    text-align: center;
    padding: 0.5em;
    margin: 1em 0 3em 0;
    

    @media(min-width:40em) {
        font-size:var(--fs-600); 
        font-family:$font-secondary; 
        color:$orange; 
        text-transform:uppercase; 
        text-decoration:none; 
        display:block;
        margin: 0;
        text-align:left;
        padding:0;
        background: none;
    }

}

.contact-btn:hover { 
    color:white; 
    cursor:pointer; 
}
