@use 'abstracts/fonts' as *;
@use 'abstracts/colors' as *;
@use 'utility/breakpoints' as *;
  
html { scroll-behavior: smooth; line-height:1.5; }
body { background-color:$orange; color:white; margin:0; padding:0;  }
body.legal { margin-bottom:0; }
a, a:visited {  color:white; transition: all .5s ease;  }
a:hover, a:active { color:white; }
p { display: block; margin-block-start: 1em; margin-block-end: 1em; margin-inline-start: 0px; margin-inline-end: 0px; }

#site { 
    overflow-x:hidden;
}

body.home #main { 
    background-color:$body-bg; 
    position:relative; 
    z-index:1; 
}

body.default #main { 
    background-color:$orange; 
    position:relative; 
    z-index:1; 

    section:first-child {
        padding-top:2em;
    }

    .site-branding {
        position:relative;
        top:0;
        left:0;
        margin-bottom: 2em;

        .logo {
            padding:0;
        }
    }

    

   
}

