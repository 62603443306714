@use 'abstracts/colors' as *;
@use 'utility/breakpoints' as *;

#hero { 
    display:flex; 
    flex-flow:column; 
    align-items:start; 
    width:100vw; 
    height:100vh; 
    position:relative; 
    overflow:hidden; 

    svg { 
        display:block; 
        position:relative; 
        z-index:5; 
        width: 100%;
    }
}

nav {
    position:absolute;
    z-index:5;
    top:2.5rem;
    right:1.5rem;

    .nav-lang {
        display: flex;
        width: 4rem;
        height: 2rem;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-content: center;
        background-color:#000;

        a {
            display:block;
            text-align:center;
            width: 2rem;
            height: 2rem;
            padding:.25em;
            font-size:var(--fs-xs);
            line-height:1.4;
            text-decoration:none;
            text-transform:uppercase;
        }

        a.active {
            background-color:$orange;
        }
    }

    @media(min-width:40em) {
        top:4.25rem;
        right:3rem;    
    }
}

.site-branding { 
    position: absolute;
    z-index: 5;
    top: 1.5rem;
    left: 1.5rem;
    width: 4rem;
    height: 4rem;
    background-color: #000;
    

    a {
        display:block;
        width:100%;
        height:100%;
        display: flex;
        justify-content: center;
        align-items: center; 
    }

    @media(min-width:40em) {
        position:absolute; 
        z-index:5; 
        top: 3rem; 
        left: 3rem;
        width: 5rem;
        height: 5rem;
    }

    .logo { 
        width: 43px;
        height: 26px; 
        
        @media(min-width:40em) {
            width: 58px;
            height: 33px;
            
        }
    }
}

.hero--title { 
    position:absolute; 
    z-index:5; 
    bottom:3rem; 
    left:1.5rem;

    @media(min-width:40em) {
        bottom:3rem; 
        left:3rem;
    }
}

.hero--img { 
    width:100%; 
    height:100vh; 
    background-color:$lightgrey; 
    background-image: url(../img/hero-background-2.jpg); 
    background-size:cover; 
    background-repeat:no-repeat; 
    background-position:center; 
    background-attachment: scroll; 
    position:absolute; 
    z-index:4; 

    @media(min-width:.40em) {
        width:100%; 
        height:100vh; 
        background-color:$lightgrey; 
        background-image: url(../img/hero-background-2.jpg); 
        background-size:cover; 
        background-repeat:no-repeat; 
        background-position:center; 
        background-attachment:scroll; 
        position:absolute; 
        z-index:4;
    }
}

.cover-container { 
    position:fixed; 
    z-index:999; 
    top:0; 
    left:0; 
    width:100%; 
    height:100vh; 
    display:flex; 
    background-color:$orange;
}


