
@use 'abstracts/fonts' as *;
@use 'abstracts/colors' as *;
@use 'utility/breakpoints' as *;

body { 
    font-family:$font-primary; 
    font-weight:400; 
    font-size:var(--fs-400); 
    line-height:1.6; 
}

h1 { 
    font-size:var(--fs-500);
    font-family:$font-secondary; 
    text-transform:uppercase;
}

h1.hero--title { 
    font-size:var(--fs-500);
    font-family:$font-secondary; 
    text-transform:uppercase;
    position: absolute;
    bottom:2rem;
    left:2rem;
    z-index:5;
    overflow: hidden;

    @media(min-width:40em) {
        bottom:4rem;
        left:4rem;
    }
}



h1.hero--title span:nth-child(1) {
    display:block;
    margin:0 0 -1.2rem 0;
    padding:0;
}

@media(min-width:65em) {
    h1.hero--title span:nth-child(1) {
        margin:0 0 -2.5rem 0;
    }
}

@media(min-width:90em) {
    h1.hero--title span:nth-child(1) {
        margin:0 0 -5.5rem 0;
    }
}

h1.hero--title span:nth-child(2) { 
    font-size:var(--fs-xl);  
    display:block; color:$orange; 
    line-height:.8; 
    white-space:wrap; 
    margin: 0;
    padding-bottom:.1em;
    background-image: url(../img/hero-tint-background.jpg);
    background-size: 230%;
    background-position: center;
    background-attachment:scroll;
    background-clip:text;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}

@media(min-width:90em) {   
    h1.hero--title span:nth-child(2) { 
        font-size:var(--fs-xl);   
        display:block; color:$orange; 
        line-height:1; 
        white-space:nowrap; 
        margin:0;
        padding-bottom:0;
        background-size: 100%;  
    }

}

h1.hero--title span:nth-child(3) {
    display:block;
    margin:-1rem 0 0 0;
    padding:0;
}

@media(min-width:65em) {
    h1.hero--title span:nth-child(3) {
        display:block;
        margin:-2.3rem 0 0 0;
        padding:0;
    }
}

@media(min-width:90em) {
    h1.hero--title span:nth-child(3) {
        display:block;
        margin:-2.3rem 0 0 0;
        padding:0;
    }
}



h2 { 
    font-size:var(--fs-600); 
    color: $orange; 
    text-transform:uppercase;
    font-family: $font-secondary; 
    font-weight: 600; 
    line-height:1; 
    margin-bottom:1rem;

    @media(min-width:65em) {
        font-size:var(--fs-600);  
        color: $orange; 
        text-transform:uppercase; 
        font-family: $font-secondary; 
        font-weight: 600; 
        line-height:1; 
        margin-bottom:1rem;
    }

}

h3 { 
    font-size:var(--fs-600); 
    font-family:$font-secondary; 
    text-transform:uppercase; 
    color:$orange; 
    line-height:1; 
    margin:.25em 0 .25em 0; 

    @media(min-width:65em) {
        font-size:var(--fs-600); 
        font-family:$font-secondary; 
        text-transform:uppercase; 
        color:$orange; 
        line-height:.9; 
        margin:.25em 0 .25em 0;
    }

}

h3.section--title { 
    font-size:var(--fs-600); 
    margin-top:0; 

    @media(min-width:65em) {
        font-size:var(--fs-600);
        margin-top:0;
    }
}

#contact {
    h3 { 
        color:white; 
        margin-bottom:0;
    }
}
