@use 'abstracts/colors' as *; 

.sphere {
    perspective: 1000px;
    position:absolute;
}

.sphere--inner {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    width: 250px;
    height: 250px;
    transform-style: preserve-3d;
    animation-name: animateSphere;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.circle {
    position: absolute;
    width: 100%;    
    height: 100%;
    border: 2px solid $orange;
    border-radius: 50%;
    opacity: 0.8;
    background: rgba(255, 255, 255, 0);
}

.zero {
    transform: rotateX(90deg);
}

.two {
    transform: rotateY(45deg);
}

.three {
    transform: rotateY(90deg);
}

.four {
    transform: rotateY(135deg);
}

.five {
    width: 173px;
    height: 173px;
    margin: 14px;
    transform: rotateX(90deg) translateZ(50px);
}

.six {
    width: 173px;
    height: 173px;
    margin: 14px;
    transform: rotateX(90deg) translateZ(-50px);
}

@keyframes animateSphere {
    0% { transform: rotateY(0deg) rotateX(0deg) rotateZ(0deg); }
    50% { transform: rotateY(360deg) rotateX(180deg) rotateZ(180deg); }
    100% { transform: rotateY(720deg) rotateX(360deg) rotateZ(360deg); }
}