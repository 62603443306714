#texture { position: fixed; right: 0; bottom: 0; min-width: 100%; min-height: 100%; z-index:10; mix-blend-mode: lighten; }

#grain {
    -webkit-animation: grain 8s steps(10) infinite;
    animation: grain 8s steps(10) infinite;
    background-image: url("../img/noise.png");
    background-repeat: repeat;
    height: 300%;
    left: -50%;
    opacity: 0.3;
    position: fixed;
    top: -100%;
    width: 300%;
    z-index: 100;
    pointer-events: none;
  }
  
  @-webkit-keyframes grain {
      0%, 100% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
      10% { -webkit-transform: translate(-5%, -10%); transform: translate(-5%, -10%); }
      20% { -webkit-transform: translate(-15%, 5%); transform: translate(-15%, 5%); }
      30% { -webkit-transform: translate(7%, -25%); transform: translate(7%, -25%); }
      40% { -webkit-transform: translate(-5%, 25%); transform: translate(-5%, 25%); }
      50% { -webkit-transform: translate(-15%, 10%); transform: translate(-15%, 10%); }
      60% { -webkit-transform: translate(15%, 0%); transform: translate(15%, 0%); }
      70% { -webkit-transform: translate(0%, 15%); transform: translate(0%, 15%); }
      80% { -webkit-transform: translate(3%, 35%); transform: translate(3%, 35%); }
      90% { -webkit-transform: translate(-10%, 10%); transform: translate(-10%, 10%); }
    }
  @keyframes grain {
      0%, 100% { -webkit-transform: translate(0, 0); transform: translate(0, 0); }
      10% { -webkit-transform: translate(-5%, -10%); transform: translate(-5%, -10%); }
      20% { -webkit-transform: translate(-15%, 5%); transform: translate(-15%, 5%); }
      30% { -webkit-transform: translate(7%, -25%); transform: translate(7%, -25%); }
      40% { -webkit-transform: translate(-5%, 25%); transform: translate(-5%, 25%); }
      50% { -webkit-transform: translate(-15%, 10%); transform: translate(-15%, 10%); }
      60% { -webkit-transform: translate(15%, 0%); transform: translate(15%, 0%); }
      70% { -webkit-transform: translate(0%, 15%); transform: translate(0%, 15%); }
      80% { -webkit-transform: translate(3%, 35%); transform: translate(3%, 35%); }
      90% { -webkit-transform: translate(-10%, 10%); transform: translate(-10%, 10%); }
    }