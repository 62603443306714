@use 'utility/breakpoints' as *;

$font-primary: acumin-pro, Verdana, sans-serif;
$font-secondary: acumin-pro-extra-condensed, Verdana, sans-serif;

:root { 
    --fs-xl: 10rem;
    --fs-600: 3rem;
    --fs-500: 2rem;
    --fs-400: 1rem;
    --fs-xs: 1rem;
  }
  @include mq(small) { 
    :root { 
        --fs-xl: 17.713rem;
        --fs-600: 9.969rem;
        --fs-500: 3.157em;
        --fs-400: 1.333rem;
    }
  }
  @include mq(medium) { 
    :root { 
        --fs-xl: 17.713rem;
        --fs-600: 9.969rem;
        --fs-500: 3.157em;
        --fs-400: 1.333rem;
    }
  }
  @include mq(large) { 
    :root { 
        --fs-xl: 23.612rem;
        --fs-600: 13.288rem;
        --fs-500: 3.157em;
        --fs-400: 1.333rem;
        --fs-xs: 1rem;
    } 
    
  }

