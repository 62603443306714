@use 'sass:map';

$breakpoints: (
    small: 40em,
    medium: 65em,
    large:90em
);

@mixin mq($key) {
    $size: map.get($breakpoints, $key);

    @media (min-width:$size) {
        @content;
    }

}
